import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from"./components/Login"
import Dashboard from "./components/Dashboard"

export default function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />}>
        
      </Route>
      <Route path="/dashboard" element={<Dashboard />}>
        
      </Route>
    </Routes>
  </BrowserRouter>
  )
}
