import React, { Component } from 'react'
import Logo from "../images/things-logo.png"
import { redirect } from './constant';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Modal, Spinner } from 'react-bootstrap';

// import { redirect } from 'react-router-dom';
export default class Login extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        open_dialog: false,
        // timezones: timezone,
        prompt_dialog: false,
        progress_dialog: false,
        message: "",
        username: "",
        password: "",
        title: "",
        reg_fullname: "",
        reg_email: "",
        // reg_timezone: timezone[0],
        reg_password: "",
        reg_confirm_password: "",
        reg_phone: "",
        reg_country: "",
        errorText: "",
        errorMail: '', errorName: '',
        showPassword: true,
        callback: "not fired",
        value: null,
        load: false,
        expired: "false",
        loading:false
      
      }
    }
    componentDidMount(){
        if(localStorage.getItem("token")!==null){
            redirect("/dashboard"); 
        }
        // console.log("token",localStorage.getItem("token"))
    }
    GetToken = () => {
        if (!this.state.username || !this.state.password) {
            alert("Please enter username and password");
        } else {
            this.setState({ loading: true });
    
            let name = this.state.username;
            let password = this.state.password;
            let a = btoa(password);
            let c = btoa(`${name}:${a}`);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Authenticate ${c}`);
    
            var raw = "";
    
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
    
            fetch("https://backend.epsumthings.com/user/auth/", requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    let data = JSON.parse(result);
                    // this.thingsDetails(data);
                    if (data.status === "success") {
                        localStorage.setItem("token", result);
                        redirect("/dashboard");
                    } else {
                        alert(data.msg);
                    }
                    console.log("dataaa", data);
                })
                .catch((error) => console.log("error", error))
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    };
    
  render() {
    return (
        <>
          {this.state.loading==false ? <></> : 
                        <div style={{display:'flex',
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,}}>
                            <div>
                            <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />
                            </div>
                        </div>
                    }
        <div className="register_page">
        <div className="new-login-form ">
          <div className="container register">
            <div className="row row1">
              <div className="col-md-12 ">
                <div className="div_main">
                  <div className="header">
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                      <defs>
                        <path id="gentle-wave"
                          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                      </defs>
                      <g className="parallaxs">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                      </g>
                    </svg>
    
                  </div>
                  <div className="div_child">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="logo text-center">
                          {/* <img src="https://www.epsumthings.com/things.png" className="img-responsive" style={{ width: "10%", margin: "0 auto" }} /> */}
                          {/* <img src={Logo} alt="Responsive image" className="img-responsive" style={{ height: "140px", objectFit: 'contain' }} /> */}
                          <img src={Logo} alt="Responsive image" className="img-responsive" style={{ height: "140px", objectFit: 'contain' }} />
    
                        </div>
                        <h2 className="text-center">Welcome To Epsumthings</h2>
                      </div>
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6">
                        <div className="col-md-12 register-right">
                          <ul
                            className="nav nav-tabs nav-justified"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="home-tab"
                                data-toggle="tab"
                                href="#home"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                              >
                                Register
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false"
                              >
                                Login
                              </a>
                            </li>
                          </ul>
    
                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade show"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              {/* <h3 className="register-heading">Register to Epsum Things</h3> */}
                              
                            </div>
                            <div
                              className="tab-pane fade show active"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="profile-tab"
                            >
                              {/* <h3  className="register-heading">Login to Epsum Things</h3> */}
                              <div className="register-form">
                                {/* <div className="col-md-12"> */}
                                <form onSubmit={(event) => { event.preventDefault(); this.GetToken() }}>
                                  <div className="form-group">
                                    <label>Your Email</label>
                                    <div className="group">
                                      <input
                                        type="email"
                                        className="input_box"
                                        required
                                        onChange={e => {
                                          this.setState({ username: e.target.value });
                                        }}
                                      />
    
                                    </div>
                                  </div>
                                  <div style={{ color: "red" }}>
                                    {/* {validateEmail(this.state.username) ? '' : this.state.errorMail} */}
                                  </div>
                                  <div className="form-group">
                                    <label> Password</label>
                                    <div className="group">
                                      <input
                                        type={this.state.showPassword ? "password" : "text"}
                                        onChange={e => {
                                          this.setState({ password: e.target.value });
                                        }}
                                        className="input_box"
                                        required
    
                                      />{
                                        this.state.showPassword ?
                                        <FaEye style={{ marginLeft: -30, cursor: "pointer", color:"black" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })}/>
                                        //   <i className="bi bi-eye" id="togglePassword" style={{ marginLeft: -30, cursor: "pointer", color:"black" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })}></i> :
                                        :
                                        <FaEyeSlash style={{ marginLeft: -30, cursor: "pointer", color:"black" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })}/>
                                        //   <i className="bi bi-eye" id="togglePassword" style={{ marginLeft: -30, cursor: "pointer", color:"black" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })}></i>
                                      }
    
                                    </div>
                                  </div>
                                  {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                    {load && (
                                      <ReCAPTCHA
                                        style={{ display: "inline-block" }}
                                        theme="light"
                                        // ref={this._reCaptchaRef}
                                        sitekey={TEST_SITE_KEY}
                                        onChange={this.handleChange}
                                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                                      />
                                    )}
                                  </div> */}
                                  <div className="form-section">
                                    <input
                                      type="submit"
                                      className="btns"
                                      value="Login"
                                      onClick={this.GetToken}
                                    />
                                  </div>
    
                                  {/* <CircularProgressModal
                                    title="Please wait while we are processing your request..."
                                    open={this.state.progress_dialog}
                                  /> */}
                                </form>
                                {/* <Forgotpassword /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
    
                      <div className="col-lg-12">
                        {/* <div className="login-with">
                          <h3>Follow Us</h3>
                          <div className="socials">
                            <ul>
                              <li className="facebook"><a href="https://www.facebook.com/epsumlabs/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li className="twitter"><a href="https://twitter.com/epsumlabs" title="twitter" target="_blank"><i className="fab fa-twitter"></i></a></li>
                              <li className="google"><a href="https://www.instagram.com/epsumlabs/" title="Instagram" target="_blank"><i className="fab fa-instagram"></i></a></li>
                              <li className="linkedin"><a href="https://in.linkedin.com/company/epsumlabs" title="linkedin" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li className="pinterest"><a href="https://github.com/epsumlabs" title="Github" target="_blank"><i className="fab fa-github"></i></a></li>
                            </ul>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}
