import React, { Component } from "react";
import { Button, Card, Table } from "react-bootstrap";
import Board from "./Board";
import { redirect } from "./constant";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    // this.GetToken();
    // Call a fetch api to get the list of the available devices
    // and set it in the state
    let data = localStorage.getItem("token");
    let data2=JSON.parse(data)
    console.log(data2?.data?.access_token)
    this.thingsDetails(data2?.data?.access_token)
  }

  thingsDetails = (token) => {
    let acesstoken = token;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${acesstoken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backend.epsumthings.com/things/groupsubgroup/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let thingsdata = JSON.parse(result);
        this.setState({
          data: Object.keys(thingsdata).length > 0 && thingsdata.data.things,
        });
      })
      .catch((error) => console.log("error", error));
  };

  GetToken = () => {
    let name = "ayeshasahoo19@gmail.com";
    let password = "Epsum@12345";
    let a = btoa(password);
    let c = btoa(`${name}:${a}`);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Authenticate ${c}`);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://backend.epsumthings.com/user/auth/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        this.thingsDetails(data);
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    console.log("data", this.state.data);
    return (
      <div className="container-fluid">
        <div style={{ height: 50, width: "100%", backgroundColor: "#474747",display:"flex",justifyContent:"space-between" }}>
          <p
            style={{
              color: "#fd6d38",
              fontSize: "1.25rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              fontWeight: "500",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              lineHeight: 1.6,
              letterSpacing: " 0.0075em",
              marginLeft:10
            }}
          >
            Epsum Things
          </p>
          <Button variant="danger" size="sm" style={{float:"right",marginRight:10,padding:10,height:45,marginTop:3}} 
          onClick={() => {
            localStorage.clear();
           redirect('/')
          }}
          >Logout</Button>
        </div>
        <div className="row">
          {this.state.data.length > 0 &&
            this.state.data.map((item, index) => {
              console.log("item",item)
              return (
                <Board
                  websocket_url={`wss://backend.epsumthings.com/thing/r/${item.thing_id}/`}
                  name={item.thing_name}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
