import React, { Component } from "react";
import { Card, Table } from "react-bootstrap";
import weather from "../../src/weather.png";
import FanOFF from "../../src/fanof.png";
import FanOn from "../../src/fanon.png";
import { FaFan } from "react-icons/fa";
import { json } from "react-router-dom";

export default class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date().toLocaleDateString(),
      currentTime: new Date().toLocaleTimeString(),
      websocket_url: this.props.websocket_url,
      details:{},
      weather:{},
      curr_state: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
      ],
    };
  }

  _handleWebSocketMessage(event) {
    const data = JSON.parse(event.data);
    console.log("socket",data.message);
    this.setState({ curr_state: data.message.values });
    this.setState({ details: data.message });
    this.getWeatherData(data?.message?.values[62], data?.message?.values[63]);
  }
  _initWebsocket() {
    // Create a websocket connection and a callback method on message
    const socket = new WebSocket(this.state.websocket_url);
    socket.onmessage = this._handleWebSocketMessage.bind(this);
    // Handle disconnection
    socket.onclose = (event) => {
      console.log(event);
    };
  }
   formatWeatherString = (data) => {
    const temperature = `${(data?.main?.temp - 273.15).toFixed(2)}°C`;
    const description = data?.weather && data?.weather[0]?.description;
    if(description!==undefined){
      return `${temperature}, ${description}`
    } else{
      return 'NA';
    }
    // return `${temperature}, ${description}`;
  };
  componentDidMount() {


    // this.intervalID = setInterval(() => this.updateDateTime(), 1000);
    this._initWebsocket();
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  // componentWillUnmount() {
  //   clearInterval(this.intervalID);
  // }

  // updateDateTime() {
  //   this.setState({
  //     currentTime: new Date().toLocaleTimeString(),
  //   });
  // }

  getWeatherData = async (city,state) => {
    const apiKey = 'ad5b47816eeec3a9bc29556286ae6c77';
  // const city = 'Bhadrak';
  // const state = 'Odisha';
  
  const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city},${state}&appid=${apiKey}`;
    try {
      const response = await fetch(apiUrl);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      this.setState({ weather: data });
      // Handle and display the weather data as needed
  
    } catch (error) {
      console.error('Error fetching weather data:', error.message);
    }
  };

  convertTimestampToFormattedString = (timestampString) => {
    const timestamp = new Date(timestampString);
    
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      // timeZone: 'UTC' // Assuming the original timestamp is in UTC
    };
  
    const formattedTime = timestamp.toLocaleTimeString('en-GB', options);
  
    return formattedTime;
  };
  
  
  render() {
    const { currentDate, currentTime, curr_state } = this.state;
    console.log("details",this.state.weather)
    return (
      <>
        <div className="col-md-6 hide-in-mobile">
          <Card
            className="m-2"
            style={{ boxShadow: "rgba(38, 57, 77, 0.3) 0px 20px 30px -10px" }}
          >
            <div
              style={{
                height: "30px",
                backgroundColor: "#474747",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "bolder",
                  margin: 0,
                  color: "#fd6d38",
                }}
              >
                {this.props.name}
              </p>
            </div>
            <div
              className="m-1"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp1
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[1] > 0 ? curr_state[1] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp2
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[2] > 0 ? curr_state[2] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp3
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[3] > 0 ? curr_state[3] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp4
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[4] > 0 ? curr_state[4] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp5
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[5] > 0 ? curr_state[5] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  H.S
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[6] > 1 ? curr_state[6] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  CO2
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[7] > 1 ? curr_state[7] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  NH3
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[8] > 1 ? curr_state[8] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  DOC
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[23]}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  A Temp
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[0] < 0 ? `+++` : curr_state[0]}
                </p>
              </div>
            </div>
            <div className="row m-2 mt-0">
              <div className="col-md-4">
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Req Temp</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                            backgroundColor: "white",
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[29]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>ReqHumidity</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[30]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Set Gas</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            // style={{
                            //   height: 30,
                            //   width: 0,
                            //   backgroundColor: "white",
                            //   boxShadow:
                            //     "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                            //   alignItems: "center",
                            //   // justifyContent: "center",
                            //   // alignSelf: "center",
                            //   // display: "flex",
                            // }}
                          >
                            {curr_state[39]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>No Of Chick</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[42]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Mortality</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                              // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[65]<0?"+++":curr_state[65]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Avg Body Wgt</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[66]}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-md-8">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img src={weather} style={{ width: 170, height: 170 }} />
                    <div style={{ marginTop: "35px" }}>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          margin: 0,
                        }}
                      >
                        {/* 23.12°cC, Haze {this.props.location} */}
                        {this.formatWeatherString(Object.keys(this.state.weather).length > 0 && this.state.weather)}
                      </p>
                      <p style={{ fontSize: "15px", margin: 0 }}>
                      Humidity: {this.state.weather?.main?.humidity} %
                      </p>
                      <p style={{ fontSize: "15px", margin: 0 }}>
                        City: {this.state.weather?.name}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "12px", marginLeft: "20px" }}>
                  <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Status: {this.state.details?.timestamp?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Inactive</span>}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bolder",
                        margin: 0,
                      }}
                    >
                      Time: {this.state.details?.timestamp?this.convertTimestampToFormattedString(this.state.details?.timestamp):"00:00:00"}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Date: {currentDate}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      FAN: {curr_state[21] > 0 ? "A" : "M"}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      COOLPAD: {curr_state[22] > 0 ? "A" : "M"}
                    </p>
                   
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: curr_state[46] > 0 ? "green" : "grey", //{curr_state[22] > 0 ? "A" : "M"},

                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Fog</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Alarm</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: curr_state[20] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Feed</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: curr_state[19] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Light</p>
                  </div>
                </div>
                <div
                  className="mt-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[15] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Heater</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[17] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Tunnel</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[16] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>
                      Cooling Pad
                    </p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[18] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>
                      CrossVent
                    </p>
                  </div>
                </div>
                {/* <br/> */}
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      // border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      // borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[9] === 0) ? "black" : (curr_state[9] === 1) ? "green" : "orange"}

                      size={35}
                    />
                    {/* <img
                        src={curr_state[9] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan1 - {curr_state[31]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      // border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      // borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[10] === 0) ? "black" : (curr_state[10] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[10] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan2 - {curr_state[32]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      // border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      // borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[11] === 0) ? "black" : (curr_state[11] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[11] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan3 - {curr_state[33]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      // border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      // borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                     color = {(curr_state[12] === 0) ? "black" : (curr_state[12] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[12] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan4 - {curr_state[34]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      // border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      // borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[13] === 0) ? "black" : (curr_state[13] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[13] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan5 - {curr_state[35]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      // border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      // borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[14] === 0) ? "black" : (curr_state[9] === 1) ? "green" : "orange"}
                      size={35}
                    />

                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan6 - {curr_state[36]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{height:"20px",backgroundColor:"#474747"}}></div>
          </Card>
        </div>

        <div className="col-sm-12 hide-in-desktop">
          <Card
            className="m-2"
            style={{ boxShadow: "rgba(38, 57, 77, 0.3) 0px 20px 30px -10px" }}
          >
             <div
              style={{
                height: "30px",
                backgroundColor: "#474747",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "bolder",
                  margin: 0,
                  color: "#fd6d38",
                }}
              >
                {this.props.name}
              </p>
            </div>
            <div
              className="m-1"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp1
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[1] > 0 ? curr_state[1] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp2
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[2] > 0 ? curr_state[2] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp3
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[3] > 0 ? curr_state[3] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp4
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[4] > 0 ? curr_state[4] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  Temp5
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[5] > 0 ? curr_state[5] : `++`}
                </p>
              </div>
            </div>
            <div
              className="m-1"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  H.S
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[6] > 1 ? curr_state[6] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  CO2
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[7] > 1 ? curr_state[7] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  NH3
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[8] > 1 ? curr_state[8] : `++`}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 16,
                    margin: 0,
                  }}
                >
                  DOC
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[23]}
                </p>
              </div>
              <div
                style={{
                  height: 50,
                  width: 100,
                  backgroundColor: "white",
                  // borderRadius: 10,
                  boxShadow:
                    "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 5,
                  margin: 5,
                }}
              >
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: 12,
                    margin: 0,
                  }}
                >
                  A Temp
                </p>
                <p style={{ color: "black", fontSize: 16, margin: 0 }}>
                  {curr_state[0] > 0 ? curr_state[0] : `++`}
                </p>
              </div>
            </div>
            <div className="row m-2 mt-0">
              <div className="col-md-3">
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Req Temp</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[29]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Req Humidity</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            // style={{
                            //   height: 30,
                            //   width: 0,
                            //   backgroundColor: "white",
                            //   boxShadow:
                            //     "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            //   alignSelf: "center",
                            //   display: "flex",
                            // }}
                          >
                            {curr_state[30]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Set Gas</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                          <div
                            style={{
                              // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // // justifyContent: "center",
                              // // alignSelf: "center",
                              // // display: "flex",
                            }}
                          >
                            {curr_state[39]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>No Of Chick</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[42]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Mortality</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[65]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "blue",textAlign:"left" }}>Avg Body Wgt</td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "800",
                            fontSize: 18,
                          }}
                        >
                           <div
                            style={{
                               // height: 30,
                              // width: 0,
                              // backgroundColor: "white",
                              // boxShadow:
                              //   "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // alignSelf: "center",
                              // display: "flex",
                            }}
                          >
                            {curr_state[66]}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-md-8">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img src={weather} style={{ width: 120, height: 120 }} />
                    <div style={{ marginTop: "35px" }}>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          margin: 0,
                        }}
                      >
                        {this.formatWeatherString(Object.keys(this.state.weather).length > 0 && this.state.weather)}
                      </p>
                      <p style={{ fontSize: "15px", margin: 0 }}>
                        Humidity: {this.state.weather?.main?.humidity} %
                      </p>
                      <p style={{ fontSize: "15px", margin: 0 }}>
                      City: {this.state.weather?.name}

                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "15px", marginLeft: "20px" }}>
                  <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Status: {this.state.details?.timestamp?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Inactive</span>}
                    </p>
                    {/* <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bolder",
                        margin: 0,
                        color: "red",
                      }}
                    >
                      {this.props.name}
                    </p> */}
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bolder",
                        margin: 0,
                      }}
                    >
                      Time: {this.state.details?.timestamp?this.convertTimestampToFormattedString(this.state.details?.timestamp):"00:00:00"}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Date: {currentDate}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      FAN: {curr_state[21] > 0 ? "A" : "M"}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      COOLPAD: {curr_state[22] > 0 ? "A" : "M"}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: curr_state[46] > 0 ? "green" : "grey", //{curr_state[22] > 0 ? "A" : "M"},

                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Fog</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Alarm</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: curr_state[20] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Feed</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 80,
                      backgroundColor: curr_state[19] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Light</p>
                  </div>
                </div>
                <div
                  className="mt-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[15] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Heater</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[17] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>Tunnel</p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[16] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>
                      Cool Pad
                    </p>
                  </div>
                  <div
                    style={{
                      height: 40,
                      width: 100,
                      backgroundColor: curr_state[18] > 0 ? "green" : "grey",
                      boShadow:
                        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "white", marginTop: "10px" }}>
                      CrossVent
                    </p>
                  </div>
                </div>
                {/* <br/> */}
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                       color = {(curr_state[9] === 0) ? "black" : (curr_state[9] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[9] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan1 - {curr_state[31]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[10] === 0) ? "black" : (curr_state[10] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[10] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan2 - {curr_state[32]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[11] === 0) ? "black" : (curr_state[11] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[11] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan3 - {curr_state[33]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                     color = {(curr_state[12] === 0) ? "black" : (curr_state[12] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[12] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan4 - {curr_state[34]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[13] === 0) ? "black" : (curr_state[13] === 1) ? "green" : "orange"}
                      size={35}
                    />
                    {/* <img
                        src={curr_state[13] > 0 ? FanOn : FanOFF}
                        style={{ height: 30, marginBottom: "5px" }}
                      /> */}
                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan5 - {curr_state[35]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                  <div
                    style={{
                      height: 60,
                      width: 80,
                      border: "0.5px solid grey",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                    }}
                  >
                    <FaFan
                      color = {(curr_state[14] === 0) ? "black" : (curr_state[14] === 1) ? "green" : "orange"}
                      size={35}
                    />

                    <p style={{ margin: "0", fontSize: "14px" }}>
                      Fan6 - {curr_state[36]}°c
                    </p>
                    {/* <p style={{ margin: '0', fontSize: '14px' }}>30</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{height:"20px",backgroundColor:"#474747"}}></div>
          </Card>
        </div>
      </>
    );
  }
}
